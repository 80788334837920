import React from "react"
import Index from "@pages/privacypolicy.js";

const PrivacypolicyJa = () => {
  const setLang = 'ja';
  return (
  <><Index langType={setLang}/></>
  )
}
export default PrivacypolicyJa
